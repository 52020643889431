import React, { useState, useEffect } from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import Timer from '../../components/molecules/Timer/index'
import { useHistory } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'
import Swal from 'sweetalert2'
import './ActiveAuction.css'

const ActiveAuction = (props) => {
    const history = useHistory()
    const [activeAuction, setActiveAuction] = useState([])
    useEffect(() => {
        if (props?.data?.results?.length > 0) {
            var dat_api = props.data.results.sort((a, b) => parseInt(a.sku) - parseInt(b.sku))
            console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh', props.sorttype)
            if (props.sorttype == 'down') {
                setActiveAuction([...dat_api.reverse()])
            } else {
                setActiveAuction([...dat_api])
            }
        } else {
            setActiveAuction([])
        }
    }, [props?.data?.results])

    const up_and_dpwn_arrow = (type) => {
        props.setSortType(type)
        console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', type)
        var data_list = activeAuction.sort((a, b) => parseInt(a.sku) - parseInt(b.sku))
        console.log('lllllllllllllllllllllllllllllllllllllllllll', data_list.reverse())
        if (type == 'down') {
            setActiveAuction([...data_list.reverse()])
        } else {
            setActiveAuction([...data_list])
        }
    }

    return (
        <div>
            <div className="Auction-Table">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">
                                <div className="d-flex align-items-center justify-content-start">
                                    ID
                                    <div style={{ lineHeight: '0px' }}>
                                        <i
                                            onClick={() => up_and_dpwn_arrow('up')}
                                            class="material-icons"
                                            style={{ fontSize: '16px', cursor: 'pointer' }}
                                        >
                                            expand_less
                                        </i>
                                        <i
                                            onClick={() => up_and_dpwn_arrow('down')}
                                            class="material-icons"
                                            style={{ fontSize: '16px', cursor: 'pointer' }}
                                        >
                                            expand_more
                                        </i>
                                    </div>
                                </div>
                            </th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Time left</th> */}
                            <th scope="col">Current Price</th>
                            <th scope="col">My Bid</th>
                            <th scope="col">My Max Bid</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(activeAuction) ? (
                            activeAuction.map((data, index) => (
                                <tr>
                                    <th scope="row" className="frstHdrRow">
                                        {data.sku}
                                    </th>
                                    <td>{data.auctionlot_title}</td>
                                    <td>{data.title}</td>
                                    {/* <td className="timer-dashboard">
                                        <Timer
                                            productTime={{
                                                start: data.date_added,
                                                end: data.date_closed,
                                            }}
                                        />
                                    </td> */}
                                    <td style={{ whiteSpace: 'pre' }}>US $ {data.latestbid}</td>
                                    <td style={{ whiteSpace: 'pre' }}>$ {data.mybid}</td>
                                    <td style={{ whiteSpace: 'pre' }}>$ {data.lastproxtamt}</td>
                                    <td style={{ whiteSpace: 'pre' }}>
                                        {data.highest_bidder_user_id ==
                                        props.userDetails.userdetails.id
                                            ? 'Winning'
                                            : 'Losing'}
                                    </td>
                                    <td className="vwBtnWrpr">
                                        <SecondaryButton
                                            label="View"
                                            onClick={() =>
                                                history.push(
                                                    // encodeURI(`/auction/${data.lotof}?page=1`),
                                                    encodeURI(
                                                        data.con_check
                                                            ? `lotview/${data.lotof}/${data.id}/${
                                                                  props.userDetails.userdetails
                                                                      .id || 0
                                                              }`
                                                            : `timedlotView/${data.lotof}/${
                                                                  data.id
                                                              }/${
                                                                  props.userDetails.userdetails
                                                                      .id || 0
                                                              }`,
                                                    ),
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        {data.highest_bidder_user_id ==
                                            props.userDetails.userdetails.id &&
                                            new Date() < new Date(data.date_added) && (
                                                <SecondaryButton
                                                    label="Retract"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'Do you want to retract this bid?',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Retract',
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                props.retractBid(data)
                                                            }
                                                        })
                                                    }}
                                                />
                                            )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {props.data.total_pagecnt ? (
                <div className="active-pgn">
                    <Pagination
                        onChange={(e, value) => {
                            props.paramsupd(value)
                        }}
                        count={
                            props.data.total_pagecnt
                                ? getPaginationCount(props.data.total_pagecnt, 10)
                                : '1'
                        }
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default ActiveAuction
